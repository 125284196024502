.slick-prev {
    left: -15px;
}

.slick-next {
    right: -15px;
}

.slick-track {
    width: 100%;
}

@media screen and (max-width: 365px) {
    .slick-prev {
        left: -25px;
    }

    .slick-next {
        right: -25px;
    }
}