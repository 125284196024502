@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,700&family=Montserrat&family=Playfair+Display:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');

body {
  margin: 0;
  font-family: "Playfair Display", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #000123; */
  background: url(/src/images/nola-new-bg-image.png);
  background-size: cover;
  /* background-repeat: no-repeat; */
  background-attachment: fixed;
  box-sizing: border-box;
}

code {
  font-family: "Playfair Display", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.title {
  /* width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: url(/src/images/nola-galaxy.webp) no-repeat;
  background-size: cover;
  height: 530px; */
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}


.home-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-phrase {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.home-phrase h1 {
  width: 50%;
  color: white;
  font-size: 48px;
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transition: width 2s, height 4s;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.home-title {
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translate(0%, 70%);
}

.main-title-image {
  transform: translate(0%, 150%);
  max-width: 100%;
  animation: appear 5s ease-in-out;
}

@keyframes appear {

  0% {
    width: 10%;
    opacity: 0;
  }

  60% {
    width: 100%;
    opacity: 1;
  }
}

.home-banner {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgb(255, 15, 123);
  background: radial-gradient(circle, rgba(255, 15, 123, 0.9990371148459384) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 0%);
}

.banner-image {
  width: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 2rem;

}

.banner-image img {
  max-width: 100%;
}

.whole-nav {
  width: 100%;
  display: flex;
  position: fixed;
}

.whole-nav-scrolled {
  background-color: #67148d;
  z-index: 1;

  .navbar-title {
    display: block;
  }
}

.nav-title {
  width: 30%;
  padding: 10px;
}

.navbar-title {
  max-width: 100%;
  display: none;
}

.navbar-title:hover {
  cursor: pointer;
}

.nav-container {
  display: flex;
  width: 100%;
}

.nav-links {
  width: 70%;
  float: right;
  display: flex;
}

.nav-link-items {
  margin: 0;
  padding: 10px;
  width: 100%;
}

.nav-link-items li,
.nav-link {
  display: inline;
  padding: 0 10px;
  justify-content: space-evenly;
  font-size: 18px;
  border-radius: 10px;
  transition: 0.3s;
  color: white;
}

.nav-link-items li:hover,
.nav-link:hover {
  cursor: pointer;
  color: #ff0f7b;
}


.nav-link {
  text-decoration: none;
}

#icon-open {
  margin: 2rem;
  margin-top: 1rem;
  font-size: 24px;
  display: none;
  color: white;
}

#icon-close {
  margin: 2rem;
  margin-top: 1rem;
  font-size: 24px;
  display: none;
  color: white;
}

#clips-section,
#about-section,
#socials-section,
#affiliate-section {
  padding-top: 70px;
}

#twitch-live-section {
  background-color: #ad3fe0;
  padding: 20px;
}




/* SOCIALS COMPONENT */

.socials-title {
  text-align: center;
  color: #280638;
  font-size: clamp(1rem, 10vw, 2rem);
}

.social-links {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.kofi-widget {
  width: 100%;
  background-color: white;
}

.kofi-widget h2 {
  text-align: center;
  padding: 5px;
  font-size: 28px;
  font-style: italic;
}

#kofiframe {
  border: none;
  width: 100%;
  /* padding: 4px; */
  background: transparent;
}



.social-link:hover {
  background-color: #67148d;
  border-radius: 20px;

}

.social-link-container a img {
  max-width: 100%;
}





/* AFFILIATE LINKS COMPONENT */

.affiliate-title {
  text-align: center;
  color: #280638;
  font-size: clamp(1rem, 10vw, 2rem);
}

.affiliate-container {
  width: 100%;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.affiliate-links {
  width: 50%;
  transform: translate(50%, 0%);
}

.affiliate-item {
  width: 30%;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.affiliate-item img {
  max-width: 100%;
  padding: 10px;
  margin: 5px;
  align-content: center;
  justify-content: center;
  text-align: center;
  /* border-radius: 17px; */
  /* border: 2px solid black; */
  /* box-shadow: 2px 2px 10px #67148d; */
}

.slick-next {
  right: -10px !important;
  z-index: 1;
}

.slick-prev {
  left: -10px !important;
  z-index: 1;
}

/* .affiliate-image {
  max-width: 100%;
} */

/* .affiliate-item p {
  font-weight: bold;
  font-style: italic;
} */

/* .affiliate-item a button {
  padding: 20px;
  width: 30%;
  background-color: #ad3fe0;
  border: none;
  border-radius: 10px;
  margin: 1rem;
  font-weight: bold;
  color: white;
}

.affiliate-item a button:hover {
  background-color: #67148d;
  cursor: pointer;
} */





/* CLIPS COMPONENT */

.clips-title {
  text-align: center;
  color: #280638;
  font-size: clamp(1rem, 10vw, 2rem);
}

.clip-carousel-container {
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.clip-slider {
  margin: 2rem;
  padding: 1rem;
}

.see-more-container {
  padding: 20px;
}

.see-more-container h2 {
  color: #280638;
}

.see-more-clips {
  text-decoration: none;
}

.see-more-clips button {
  width: 20%;
  padding: 20px;
  background-color: #ad3fe0;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  color: white;
}

.see-more-clips button:hover {
  cursor: pointer;
  background-color: #67148d;
}





/* INDIVUDUAL CLIP COMPONENT */
.clip-embed-container {
  padding: 10px;
}





/* ABOUT COMPONENT */
.about-info-container {
  width: 100%;
  display: flex;
}

.about-image {
  width: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.about-image img {
  max-width: 100%;
  border-radius: 50%;
  border: 2px solid black;
  box-shadow: 2px 2px 10px #67148d;
}

/* #image-slide {
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }

} */



.about-content {
  width: 50%;
  padding: 20px;
}

.about-content h1 {
  color: #280638;
  font-size: clamp(1rem, 10vw, 2rem);
}

.text-scroll,
.social-scroll,
.clip-scroll,
.affiliate-scroll {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.text-scroll.slide-in,
.social-scroll.slide-in,
.clip-scroll.slide-in,
.affiliate-scroll.slide-in {
  opacity: 1;
  transform: translateY(0);
}

.about-content p {
  font-size: clamp(1rem, 1.5vw, 2rem);
  color: white;
}

hr {
  margin: 1rem;
}

.teams-title {
  text-align: center;
  color: #280638;
  font-size: clamp(1rem, 10vw, 2rem);
}

.teams-list-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.team-image {
  padding: 20px;
  max-width: 100%;
}

.team-image img {
  max-width: 100%;
}

.team-info-text {
  max-width: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.team-info-text h2 {
  color: white;
  font-size: clamp(1rem, 10vw, 2rem);
}

.team-info-text p {
  font-size: clamp(1rem, 1.3vw, 2rem);
  color: white;
}

.team-visit-link {
  padding: 20px 0;
  width: 100%;
}

.team-visit-link p {
  font-size: clamp(1rem, 1.3vw, 2rem);
  color: #000123;
}

.team-link button {
  width: 50%;
  padding: 20px;
  border-radius: 20px;
  background-color: #ad3fe0;
  border: none;
  font-weight: bold;
  color: white;

}

.team-link button:hover {
  cursor: pointer;
  background-color: #67148d;
  color: white;
}

.team-divider {
  width: 100%;
  display: none;
}





/* FOOTER STYLES */
.footer-container {
  width: 100%;
  /* background-color: #67148d; */
}

.footer-divider {
  margin: 0;
}

.footer-content {
  width: 100%;
  display: flex;
}

.footer-links {
  width: 50%;
}

.footer-links h2 {
  color: white;
  padding: 10px;
}

.footer-links {
  width: 50%;
}

.footer-title {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-title img {
  float: right;
}

.footer-link {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.footer-link:hover {
  color: #ff0f7b;
  text-decoration: underline;
  cursor: pointer;
}

.footer-link li {
  padding: 10px;
}

.footer-list li {
  padding: 10px;
  color: white;
}

.team-link {
  text-decoration: none;
  color: white;
}

.team-link:hover {
  color: #ff0f7b;
  text-decoration: underline;
}

.footer-email {
  text-decoration: underline;
  color: white;
}

.footer-email:hover {
  color: #ff0f7b;
}

.footer-list li {
  list-style-type: none;
}

.footer-scroll:hover {
  cursor: pointer;
}

.footer-title-name {
  max-width: 100%;
}

.footer-socials {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.footer-social-img {
  width: 10%;
  padding: 10px;
}

.social-image {
  max-width: 100%;
}

.footer-copyright p {
  margin: 0;
  text-align: center;
  color: white;
}

.footer-copyright p a {
  color: white;
}

/* TWITCH COMPONENT */
.twitch-live-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
}

.twitch-live {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.twitch-profile-pic-live {
  max-width: 20%;
  border-radius: 50%;
  border: 3px solid red;
  box-shadow: 1px 1px 3px red;
}

.twitch-profile-pic-offline {
  max-width: 20%;
  border-radius: 50%;
  border: 3px solid grey;
}

.twitch-live-logo {
  max-width: 10%;
}

.twitch-live p {
  text-align: center;
  padding: 10px;
  font-size: 18px;
  color: white;
}

.twitch-live-button {
  background-color: #280638;
  border: none;
  border-radius: 20px;
  width: 35%;
}

.twitch-live-button:hover {
  background-color: #67148d;
  border-radius: 20px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal {
  background-color: #69208b;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.modal-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-title-image {
  max-width: 100%;
  padding: 10px;
}

.modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.modal-button button {
  padding: 10px;
  width: 100%;
  background-color: #ad3fe0;
  color: white;
  border: none;
  border-radius: 20px;
  text-align: center;
}

.modal-content button:hover {
  background-color: #67148d;
}

.close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 36px;
  color: white;
}

/* FORM COMPONENT */
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container {
  background-color: #69208b;
  width: 70%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 2px 2px 10px #67148d;
}

.form-container h2 {
  color: white;
}

.form-container p {
  text-align: center;
  color: white;
}

.form-field {
  width: 100%;
  padding: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.form-field label {
  color: white;
}

.form-field input {
  width: 70%;
}

.form-field textarea {
  width: 70%;
}

.form-field input,
.form-field textarea {
  background-color: #a59eb0;
  padding: 10px;
  font-family: "Playfair Display", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-submit-button {
  padding: 20px;
  width: 30%;
  background-color: #280638;
  border: none;
  border-radius: 10px;
  margin: 1rem;
  font-weight: bold;
  color: white;
}

.form-submit-button:hover {
  background-color: #180222;
  cursor: pointer;
}





/* RESPONSIVE MEDIA QUERIES */

@media screen and (max-width: 1219px) {
  .home-phrase h1 {
    width: 70%;
  }

}

@media screen and (max-width: 1092px) {
  .clip-iframe {
    width: 450px;
    height: 278px;
  }
}

@media screen and (max-width: 948px) {
  .clip-iframe {
    width: 400px;
    height: 248px;
  }

  .twitch-live-button {
    width: 40%;
  }

  #glytch-partner {
    width: 600px;
    height: 600px;
  }

  .form-container {
    width: 75%;
  }
}

@media screen and (max-width: 880px) {

  .title {
    height: 400px;
  }

  .main-crown-image,
  .home-phrase h1,
  .main-title-image {
    display: none;
  }

  .nav-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-title {
    max-width: 100%;
  }

  .nav-container {
    display: none;
    background-color: #ad10ad;
    height: 100vh;
    width: 420px;
    z-index: 1;
    position: absolute;
    margin: 0;
  }

  .show {
    display: block;
    background-color: #67148d;
    height: 100vh;
    width: 420px;
    z-index: 1;
    position: absolute;
    margin: 0;
  }

  .nav-link-items li {
    display: block;
    color: white;
    padding: 10px;
  }

  .nav-link {
    color: white;
  }

  .nav-link {
    margin: 10px 0;
  }

  #icon-open {
    display: block;
    z-index: 2;
    position: relative;
  }

  #icon-close {
    display: none;
  }

  #icon-open:hover,
  #icon-close:hover {
    cursor: pointer;
  }

  .text-scroll,
  .social-scroll,
  .clip-scroll,
  .affiliate-scroll {
    opacity: 1;
    transition: none;
  }

  .text-scroll.slide-in,
  .social-scroll.slide-in,
  .clip-scroll.slide-in,
  .affiliate-scroll.slide-in {
    opacity: 1;
    transform: none;
  }

  .social-links {
    display: block;
    text-align: center;
  }

  .social-link {
    width: 50%;
    padding: 10px;
  }


  .home-banner {
    background: rgb(255, 15, 123);
    background: radial-gradient(circle, rgba(255, 15, 123, 1) 0%, rgba(0, 1, 35, 1) 50%, rgba(0, 1, 35, 1) 50%);
  }

  .banner-image {
    width: 100%;
  }

  .banner-playstation {
    width: 280px;
    height: 250px;
  }

  .banner-monitor {
    width: 280px;
    height: 190px;
  }

  .banner-pc {
    width: 280px;
    height: 280px;
  }

  .clip-iframe {
    width: 350px;
    height: 250px;
  }

  .see-more-clips button {
    width: 50%;
  }

  .affiliate-item a button {
    width: 50%;
  }

  #glytch-partner {
    width: 580px;
    height: 580px;
  }

  #ubisoft-partner {
    width: 528px;
    height: 435px;
  }

  #streamline-affiliate {
    width: 528px;
    height: 290px;
  }

  .about-image {
    padding: 30px 0px 0px 0px;
  }

  .about-image img {
    width: 300px;
    height: 440px;
  }

  .about-info-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .about-content {
    width: 100%;
    text-align: center;
    padding: 0;
  }

  .about-content h1 {
    text-align: center;
  }

  .about-team-link button {
    width: 50%;
  }

  .footer-content {
    flex-direction: column-reverse;
  }

  .footer-title {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
  }

  .footer-title img {
    float: none;
    max-width: 50%;
  }

  .footer-list {
    padding: 0;
  }

  .footer-links {
    text-align: center;
    width: 100%;
  }

  .footer-social-img {
    width: 30%;
  }

  .clip-iframe {
    width: 100%;
    height: 278px;
  }

  .affiliate-item p {
    font-size: 12px;
    padding: 10px;
  }

  .about-image {
    width: 100%;
  }

  .team-info {
    width: 100%;
    transform: translate(0%, 0%);
  }

  .twitch-live-button {
    width: 50%;
  }

  .form-container {
    width: 80%;
  }

  .form-submit-button {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .title {
    height: 350px;
  }

  #icon-open {
    margin: 10px 20px;
  }

  .main-title-name {
    max-width: 100%;
    width: 500px;
    height: 80px;
  }

  .nav-link-items li {
    text-align: left;
  }

  .banner-playstation {
    width: 240px;
    height: 210px;
  }

  .banner-monitor {
    width: 240px;
    height: 150px;
  }

  .banner-pc {
    width: 240px;
    height: 240px;
  }

  .teams-list-container {
    flex-direction: column;
  }

  .team-info-text {
    width: 100%;
  }

  .team-divider {
    display: block;
  }

  .social-link-container {
    width: 100%;
    margin: 1rem 0;
  }

  .clip-iframe {
    width: 300px;
    height: 200px;
  }

  .affiliate-links {
    width: 100%;
    transform: translate(0%, 0%);
  }

  .slick-next {
    right: 0px !important;
  }

  .slick-prev {
    left: 0px !important;
  }

  .affiliate-item {
    width: 100%;
  }

  .affiliate-item a button {
    width: 80%;
    padding: 10px;
  }

  #glytch-partner {
    width: 576px;
    height: 576px;
  }

  #ubisoft-partner {
    height: 335px;
  }

  #streamline-affiliate {
    height: 250px;
  }


  .team-image {
    height: 150px;
  }

  .about-image img {
    width: 250px;
    height: 400px;
  }

  .twitch-live-button {
    width: 60%;
  }

  .form-container {
    width: 90%;
  }

  .form-submit-button {
    width: 80%;
  }
}

@media screen and (max-width: 517px) {
  .twitch-live-button {
    width: 65%;
  }
}

@media screen and (max-width: 450px) {
  .title {
    height: 210px;
  }

  #icon-open {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-title-name {
    width: 400px;
    height: 80px;
  }

  .nav-link-items li {
    padding: 10px 0;
  }

  .nav-link {
    font-size: 14px;
  }

  .banner-playstation {
    width: 200px;
    height: 170px;
  }

  .banner-monitor {
    width: 200px;
    height: 110px;
  }

  .banner-pc {
    width: 200px;
    height: 200px;
  }

  #clips-section,
  #about-section,
  #socials-section,
  #affiliate-section {
    padding-top: 30px;
  }

  .clip-iframe {
    max-width: 100%;
    height: 170px;
  }

  #glytch-partner {
    width: 500px;
    height: 500px;
  }

  #ubisoft-partner {
    width: 382px;
    height: 235px;
  }

  #streamline-affiliate {
    width: 382px;
    height: 190px;
  }

  .about-image img {
    width: 180px;
    height: 300px;
  }

  .footer-title-name {
    width: 420px;
    height: 50px;
  }

  .twitch-live-button {
    width: 70%;
  }

  .form-container {
    width: 95%;
  }

}

@media screen and (max-width: 430px) {
  .twitch-live-button {
    width: 75%;
  }
}

@media screen and (max-width: 395px) {
  .clip-iframe {
    width: 700px;
  }

  .clip-slider {
    margin: 14px;
  }

  .twitch-live-button {
    width: 80%;
  }

  #glytch-partner {
    width: 450px;
    height: 450px;
  }
}

@media screen and (max-width: 386px) {
  .twitch-live-button {
    width: 85%;
  }
}

@media screen and (max-width: 361px) {
  .twitch-live-button {
    width: 90%;
  }
}